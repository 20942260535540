// Libs
import React from 'react';
import { useFormikContext } from 'formik';

// Hooks
import { useErrorHandling } from '@use-cases/notifications';
import { useDISCountries } from '@repositories/disCountry';

// Components
import Select, { SelectProps } from '../Select';
import Loading from '@components/Loading';

// Types
import { Country } from '@typings/graphql';

// Utils
import { getSortedCountries } from '@utils/getSortedCountries';

const generateOptions = (countries: Country[]) =>
  countries.map(c => ({ label: c.name, value: c.id }));

type Props = Omit<SelectProps, 'options'> & { stateName: string };

const CountrySelect: React.FC<Props> = props => {
  const { data, error } = useDISCountries();

  useErrorHandling(error?.message, !!error, 'fetch-countries.error');

  const { setFieldValue } = useFormikContext();

  const handleChange = (value: string) => {
    setFieldValue(props.name, value);
    setFieldValue(props.stateName, '');
  };

  if (!data) {
    return <Loading inline small />;
  }

  return (
    <Select
      onChange={handleChange}
      searchable
      options={generateOptions(getSortedCountries(data.countries))}
      {...props}
    />
  );
};

export default CountrySelect;

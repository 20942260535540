// Libs
import React, { useEffect } from 'react';
import { useTranslation } from '@external/react-i18next';
import { isEqual } from 'lodash';
import { useField } from 'formik';

// Hooks
import { useErrorHandling } from '@use-cases/notifications';
import { useDISStates } from '@repositories/disCountry';

// Components
import Loading from '@components/Loading';
import Select, { SelectProps } from '../Select';
import TextField from '../TextField';

// Types
import { State } from '@typings/graphql';

const generateOptions = (countryId: string, states: State[]) =>
  states
    .filter(s => s.countryId === countryId)
    .map(s => ({ label: s.name, value: s.id }));

type Props = Omit<SelectProps, 'options'> & {
  countryId: string;
  name?: string;
  textStateName?: string;
  textStateLabel?: string;
  hasStatesName?: string;
};

const StateSelect: React.FC<Props> = ({
  countryId,
  name = 'state',
  textStateName = 'address.internationalProvince',
  textStateLabel,
  hasStatesName,
  ...props
}) => {
  // We have this dummy field in the Formik fields for having
  // states available in side `Yup` scheme
  // we have validation which depends if we have received values from DIS or not
  const [field, , helpers] = useField(hasStatesName || '');

  const { t } = useTranslation();
  const { data, error } = useDISStates(countryId);
  useErrorHandling(error?.message, !!error, 'fetch-states.error');

  useEffect(() => {
    const hasStates = Boolean(data?.states.length);

    // If we received some states
    // and our `states` is not equal to states which are received then update
    if (!isEqual(field.value, hasStates)) {
      helpers.setValue(hasStates);
    }
  }, [data?.states]);

  if (!data) {
    return <Loading inline small />;
  }

  if (!data.states.length) {
    return (
      <TextField name={textStateName} label={textStateLabel || props.label} />
    );
  }

  return (
    <Select
      searchable
      options={generateOptions(countryId, data.states)}
      name={name}
      label={t('form.address.state-label', 'State/Province/Territory')}
      {...props}
    />
  );
};

export default StateSelect;
